/* --------------------
    WaypointList
   -------------------- */ 

.listRow {
  display: flex;
  justify-content: space-between; /* コンテンツを左右に分散させる */
  align-items: center; /* アイテムを縦方向の中央に揃える */
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.deleteButton {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  width: 80px;
  margin-left: 10px; /* ボタン間に隙間を作る */
}