/* --------------------
    RouteList
   -------------------- */ 
.routelist {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none; /* 中黒を非表示にする */
    padding-left: 0; /* 左側のパディングを削除する */
    margin-left: 0; /* 左側のマージンを削除する（必要に応じて） */
    max-height: 150px;
    overflow-y: auto; /* コンテンツがはみ出た場合にスクロールバーを表示する */
    border: 1px solid #ccc;
}

.listRow {
  display: flex;
  justify-content: space-between; /* コンテンツを左右に分散させる */
  align-items: center; /* アイテムを縦方向の中央に揃える */
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.routeId {
  /* routeIdが左寄せになるようにするためのスタイル調整が必要な場合はここに追記 */
}

.buttonGroup {
  /* publishButtonとdeleteButtonを包含するコンテナ */
  display: flex;
  justify-content: flex-end; /* ボタンを右寄せにする */
}

.publishButton, .deleteButton {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  width: 80px;
  margin-left: 10px; /* ボタン間に隙間を作る */
}