/* App.module.css または適切なCSSファイルに追加 */
.container {
  position: relative;
  height: 100vh; /* 画面全面に地図を表示 */
}

.roadmap {
  width: 100%;
  height: 100%;
}

.overlayButton {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5); /* 半透明 */
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px; /* ボタン間のマージン */
  height: 40px;
  cursor: pointer;
}

/* 各ボタンの位置を指定 */
.buttonMenu {left: 20px; top: 50px; }
.buttonCurrentLocation {left: 20px; top: 100px; }
.buttonOriginInput {left: 20px; top: 150px; }
.buttonDestinationInput {left: 20px; top: 200px; }
.buttonWaypointInput {left: 20px; top: 250px; }
.buttonClearWaypoints {left: 20px; top: 300px; }
.buttonSaveSettings {left: 20px; top: 400px; }

.buttonActive {
  background-color: #FF2281; /* アクティブなボタンの背景色 */
  color: white; /* アクティブなボタンのテキスト色 */
}

.overlayLabel { position: absolute;
  background-color: rgba(255, 255, 255, 0.5); /* 半透明 */
  border: none;
  padding: 10px;
  height: 20px;
  cursor: pointer;
}

.overlayContainer {
  left: 20px;
  top: 360px;
  background-color: rgba(255, 255, 255, 0.5); /* 半透明 */
  position: absolute;
  display: flex;
}
.overlayInput {
  flex-grow: 1;
  border: none;
  padding: 10px;
  margin-right: 10px; /* ボタン間のマージン */
  height: 20px;
  cursor: pointer;
}
.inputRouteId {
  padding-right: 24px; /* アイコンのスペースを確保 */
}
.clearIcon {
  left: 144px;
  cursor: pointer;
  height: 20px;
  text-align: center;
}

.menuArea {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明 */
  color: white;
  padding: 20px;
  display: none; /* 初期状態では非表示 */
  width: 520px;
}

.menuButton {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* メニュー表示時のスタイル */
.show {
  display: block;
}

/* スマートフォン向けのスタイル */
@media (max-width: 600px) {
  /* App.module.css または適切なCSSファイルに追加 */
    .container {
      position: relative;
      height: 100vh;
      /* 画面全面に地図を表示 */
    }
  
    .roadmap {
      width: 100%;
      height: 100%;
    }
  
    .overlayButton {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
      /* 半透明 */
      border: none;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;
      /* ボタン間のマージン */
      height: 40px;
      cursor: pointer;
    }
  
    /* 各ボタンの位置を指定 */
    .buttonMenu {
      left: 20px;
      top: 50px;
    }
  
    .buttonCurrentLocation {
      left: 20px;
      top: 100px;
    }
  
    .buttonOriginInput {
      left: 20px;
      top: 150px;
    }
  
    .buttonDestinationInput {
      left: 20px;
      top: 200px;
    }
  
    .buttonWaypointInput {
      left: 20px;
      top: 250px;
    }
  
    .buttonClearWaypoints {
      left: 20px;
      top: 300px;
    }
  
    .buttonSaveSettings {
      left: 20px;
      top: 400px;
    }
  
    .buttonActive {
      background-color: #FF2281;
      /* アクティブなボタンの背景色 */
      color: white;
      /* アクティブなボタンのテキスト色 */
    }
  
    .overlayLabel {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
      /* 半透明 */
      border: none;
      padding: 10px;
      height: 20px;
      cursor: pointer;
    }
  
    .overlayContainer {
      left: 20px;
      top: 360px;
      background-color: rgba(255, 255, 255, 0.5);
      /* 半透明 */
      position: absolute;
      display: flex;
    }
  
    .overlayInput {
      flex-grow: 1;
      border: none;
      padding: 10px;
      margin-right: 10px;
      /* ボタン間のマージン */
      height: 20px;
      cursor: pointer;
    }
  
    .inputRouteId {
      width: 100px;
      padding-right: 24px;
      /* アイコンのスペースを確保 */
    }
  
    .clearIcon {
      left: 144px;
      cursor: pointer;
      height: 20px;
      text-align: center;
    }
  
    .menuArea {
      position: absolute;
      top: 20px;
      right: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      /* 半透明 */
      color: white;
      padding: 20px;
      display: none;
      /* 初期状態では非表示 */
      width: 520px;
    }
  
    .menuButton {
      position: absolute;
      top: 20px;
      left: 20px;
      background-color: rgba(255, 255, 255, 0.5);
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  
    /* メニュー表示時のスタイル */
    .show {
      display: block;
    }

}
