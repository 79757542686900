/* ====================
   RESET
   ==================== */
/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
    box-sizing: border-box
}

* {
    margin: 0
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%
}

input,
button,
textarea,
select {
    font: inherit
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word
}

#root,
#__next {
    isolation: isolate
}

/* ====================
   GLOBAL STYLES
   ==================== */
@font-face {
  font-family: 'MainFont';
  src: url('./assets/fonts/ToaHI-Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'DecimalFont';
  src: url('./assets/fonts/ToaHI-Rg.ttf') format('truetype');
  /* src: url('./assets/fonts/fgzerorabaulfont.otf') format('opentype'); */
}

:root {
  --color-base: #00f0f1;
  --color-background: #222124;
}

.font_base {
  font-family: MainFont;
  white-space: pre-wrap;
}

.font_decimal {
  font-family: DecimalFont;
  white-space: pre-wrap;
}

.fontcolor_base {
  color: var(--color-base);
  text-shadow: 0 0 0.3vw currentColor;
}
.fontcolor_title {
  color: var(--color-background);
  text-shadow: 0 0 0.3vw currentColor;
}

.backgroundcolor_base {
  background-color: var(--color-background);
}


/* ====================
    ELEMENTS
   ==================== */

/* ====================
    PARTS
   ==================== */